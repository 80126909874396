<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm para janelas de apartamento</h2>

            <p>
              Por que é tão importante instalar o insulfilm nas janelas de sua
              casa, condomínio ou apartamento. Já há algum tempo, a aquisição da
              película de insulfilm por parte de clientes, os quais desejam
              introduzir o produto em janelas de apartamentos, aumentou muito.
              Em sua maior parte, os clientes têm a certeza de que podem ter
              ótimos benefícios.
            </p>

            <p>
              Com o passar do tempo foi comprovando toda a sua eficiência e boa
              funcionalidade no dia a dia.
            </p>

            <p>
              Pensando nisso, a MF Film vem mostrar para os seus clientes as
              vantagens de usar películas de controle solar e privacidade em
              janelas de apartamento.
            </p>
            <p>
              Com produtos de primeira linha no mercado que a MF Film oferece,
              você poderá alcançar ótimos resultados!
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Benefícios do insulfilm para janelas de apartamentos</h3>

            <p>
              <strong>Impede a incidência de luminosidade</strong>: com
              propriedade de
              <strong> bloquear em até 99% raios ultravioletas </strong>, o
              insulfilm aplicado em janelas de apartamentos, barrando a entrada
              de luz (dependendo da película escolhida) e radiação u.v em
              excesso no ambiente interno, além de que contribua para manter a
              temperatura estável e a <strong> boa visão externa </strong>.
            </p>

            <p>
              Deixando as janelas dos apartamentos mais
              <strong>sofisticadas</strong>: com um produto de alta qualidade,
              como insulfilme para janelas de apartamentos, os ambientes que
              tiverem a sua aplicação poderão ter uma apresentação mais bonita e
              agradável.
            </p>

            <p>
              O insulfilm para janela de apartamento possui uma propriedade
              muito importante: alguns de seus modelos <strong>impedem a visão total</strong>
              para a <strong>parte do lado interno</strong> de um ambiente. Por isso, quando
              aplicado, diminui a probabilidade de que algum indivíduo
              desconhecido conheça a rotina dos moradores, estando
              impossibilitado de eventuais atos criminosos.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[1].thumb"
                :title="photos[1].title"
                :description="photos[1].description"
                :idLocation="photos[1].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Instalação do insulfilm para janelas de apartamento</h3>

            <p>
              É possível aplicar insulfilmes para janelas de apartamento com
              materiais de qualidade e com garantia de fábrica, impedindo a
              visualização de fora para o interior do ambiente, gerando
              privacidade e reduzindo o calor e radiação UV do ambiente.
            </p>

            <p>
              A <router-link :to="{ name: 'about' }">MF Film</router-link> tem
              mais de 18 anos de experiência com profissionais especializados na
              instalação de insulfilms e garante o serviço completo.
            </p>

            <p>
              <router-link :to="{ name: 'quotation' }"
                >Faça uma cotação</router-link
              >, fale com um especialista e veja como as películas podem ajudar
              na privacidade, decoração e segurança das janelas.
            </p>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");

export default {
  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
  },

  data() {
    return {
      layoutLoad: false,
      index: null,
      photos: [
        {
          description:
            "Insulfilm branco jateado aplicado na janela para fornecer pricavidade ao local",
          idLocation: 7,
          src: "/images/fotos/apartamento-vila-nova-conceicao/pelicula-transparente-sacada-apartamento-694",
          thumb:
            "/images/fotos/apartamento-vila-nova-conceicao/small/pelicula-transparente-sacada-apartamento-694",
          title: "Película para Sacada",
        },
        {
          description:
            "Aplicação de película para proteger os móveis dos raios solares preservando a fachada",
          idLocation: 7,
          src: "/images/fotos/apartamento-rio/pelicula-transparente-sacada-apartamento-6",
          thumb:
            "/images/fotos/apartamento-rio/small/pelicula-transparente-sacada-apartamento-6",
          title: "Película para Sacada",
        },
      ],
    };
  },

  mounted: function () {
    //console.log(this.photos)
    // console.log('mounted')
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm para janelas de apartamento");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm em janelas de apartamentos para privacidade estética e proteção"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>